<template>
  <div v-if="criterion">
    <div class="columns">
      <div class="column">
        <div>
          <p class="title is-6" :style="{ color: rqmt.color }">
            {{ topic.title }}
          </p>
        </div>
      </div>
      <div class="column">
        <p class="title is-6" :style="{ color: rqmt.color }">
          {{ rqmt.tnr }}.{{ rqmt.rnr }} {{ rqmt.title }}
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <p class="title is-4" :style="{ color: rqmt.color }">
          {{ criterion.title }}
        </p>
        <p class="subtitle is-6">{{ criterion.descr }}</p>
      </div>
      <AuditHint
        class="column is-1"
        :hints="criterion.hintMask"
        :grade="item.grade"
        :prevNr="criterion.prevNr"
      />
    </div>
    <div class="is-flex is-flex-direction-row-reverse mb-4">
      <b-tooltip position="is-left" label="Angaben zu dem letzten Audit resp. der letzten Selbstevaluation einsehen">
        <b-button v-if="item.prev" icon-right="history" @click="showPrev = !showPrev" />
      </b-tooltip>
    </div>
    <div class="columns">
      <div class="column">
        <b-field horizontal label="Merken">
          <b-checkbox v-model="review" type="is-info" class="mt-1" />
        </b-field>
        <b-field horizontal label="Ergebnis">
          <b-field>
            <b-radio-button v-model="result" native-value="3" type="is-success">
              <span>ja</span>
            </b-radio-button>
            <b-radio-button
              v-model="result"
              native-value="2"
              type="is-warning"
              v-if="criterion.hintMask.includes('p')"
            >
              <span>teilweise</span>
            </b-radio-button>
            <b-radio-button v-model="result" native-value="1" type="is-danger">
              <span>nein</span>
            </b-radio-button>
            <b-radio-button v-model="result" native-value="99" type="is-danger">
              <span>kann nicht beurteilt werden</span>
            </b-radio-button>
          </b-field>
        </b-field>
      </div>
      <div v-if="showPrev && item.prev" class="column is-one-third">
        <div class="box mt-5 is-hidden-mobile">
          <AuditRating :grade="item.prev.grade" />
        </div>
        <div class="box is-hidden-tablet">
          <b-field horizontal label="Ergebnis">
            <AuditRating :grade="item.prev.grade" />
          </b-field>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field horizontal :label="pubNoteLabel">
          <b-input
            :disabled="!item.grade"
            @input="$emit('tiping', true)"
            v-model="pub"
            type="textarea"
          />
        </b-field>
      </div>
      <div v-if="showPrev && item.prev" class="column is-one-third">
        <div class="box">
          {{ item.prev.pub }}
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field v-if="showNotes" horizontal label="Notiz intern">
          <b-input
            :disabled="!item.grade"
            @input="$emit('tiping', true)"
            v-model="int"
            type="textarea"
          />
        </b-field>
      </div>
      <div v-if="showPrev && item.prev" class="column is-one-third">
        <div v-if="showNotes" class="box">
          {{ item.prev.int }}
        </div>
      </div>
    </div>
    <div v-if="item.tipsntricks" class="columns">
      <div class="column">
        <b-field horizontal label="Tipps & Tricks">
          <div>{{ item.tipsntricks }}</div>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

import { mapGetters } from 'vuex'

import AuditHint from './AuditHint.vue'
import AuditRating from './AuditRating.vue'

export default {
  components: {
    AuditHint,
    AuditRating
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    cat: {
      type: String,
      default: 'S'
    }
  },
  data () {
    return {
      showPrev: false
    }
  },
  computed: {
    ...mapGetters({
      criterionById: 'manual/criterionById',
      rqmtById: 'manual/rqmtById',
      topicById: 'manual/topicById'
    }),
    criterion () {
      return this.criterionById(this.item.ci)
    },
    rqmt () {
      return this.rqmtById(this.item.ri)
    },
    topic () {
      return this.topicById(this.item.tnr)
    },
    showNotes () {
      return this.cat !== 'S'
    },
    pubNoteLabel () {
      return this.cat === 'S' ? 'Bemerkung' : 'Verbesserungsschwerpunkte'
    },
    result: {
      get () {
        return this.item.grade.toString()
      },
      set (value) {
        this.item.grade = parseInt(value)
      }
    },
    review: {
      get () {
        return this.item.review === 1
      },
      set (value) {
        this.item.review = value ? 1 : 0
      }
    },
    pub: {
      get () {
        return this.item.pub
      },
      set: debounce (async function (val) {
        this.item.pub = val
        this.$emit('tiping', false)
      }, 500)
    },
    int: {
      get () {
        return this.item.int
      },
      set: debounce(async function (value) {
        this.item.int = value
        this.$emit('tiping', false)
      }, 500)
    }
  }
}
</script>
