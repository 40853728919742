<template>
  <div>
    <AuditEdit />
  </div>
</template>

<script>
import AuditEdit from '@/components/AuditEdit.vue'

export default {
  components: {
    AuditEdit
  }
}
</script>
