<template>
  <div v-if="loading">
    Loading ...
  </div>
  <div v-else-if="audit">
    <div class="is-flex is-justify-content-space-between p-2 has-background-light">
      <div>
        <span class="pr-4">{{ audit.store.id }} - {{ audit.store.name }}</span>
        <span>{{ audit.cat | audtCatDescr }} {{ audit.year }}</span>
      </div>
      <div class="is-flex is-align-items-center">
        <b-icon v-if="syncing" icon="sync-alt" custom-class="fa-spin" />
        <small v-if="lastSync">
          Zuletzt synchronisiert: <b>{{ $dayjs(lastSync).format('DD.MM.YYYY HH:mm [Uhr]') }}</b>
        </small>
        <b-tooltip label="Alle Ergebnisse auf Ja setzen">
          <b-button v-if="canSetAll" :disabled="unprocCount === 0 || !isOnline" @click="setAll" icon-right="check" size="is-small" class="ml-2" />
        </b-tooltip>
      </div>
    </div>
    <div class="columns mt-1">
      <div class="column">
        <b-field horizontal label="Filter">
          <b-select
            v-model="filter"
            placeholder="alle Kriterien"
            :disabled="isTiping"
            @input="filterChanged"
            rounded
          >
            <option value="all">alle Kriterien</option>
            <option value="todos">nicht bearbeitet</option>
            <option value="nos">nein Kriterien</option>
            <option value="reviews">vorgemerkte Kriterien</option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-flex is-align-items-center">
        <b-button
          :disabled="!hasOneOfRoles(['QmsAdmin', 'QmsAuditor']) || isTiping"
          icon-left="chart-bar"
          tag="router-link"
          :to="{ name: 'AuditSummary', params: { id: $route.params.id }}"
          rounded
        >
          Übersicht
        </b-button>
        <b-button
          icon-left="flag-checkered"
          @click="confirmFinalize"
          :disabled="unprocCount > 0 || !isOnline || isTiping"
          rounded
        >
          Beenden
        </b-button>
        <span class="has-text-danger ml-2">{{ unprocCount }}</span>
      </div>
      <div class="column bulma-overlay-mixin-parent">
        <b-pagination
          v-if="!isTiping"
          @change="pageChange"
          :total="items.length"
          :current.sync="current"
          simple
          order="is-right"
          rounded
          per-page="1"
          icon-prev="arrow-left"
          icon-next="arrow-right"
        >
        </b-pagination>
      </div>
    </div>
    <AuditEditItem v-if="curItem" :item="curItem" :cat="audit.cat" @tiping="(e) => isTiping = e" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import filters from '@/mixins/filters'

import AuditService from '@/services/audits.service'

import AuditEditItem from '@/components/AuditEditItem.vue'

export default {
  components: {
    AuditEditItem
  },
  mixins: [filters],
  data () {
    return {
      loading: false,
      curItem: null,
      audit: null,
      isTiping: false
    }
  },
  watch: {
    curItem: {
      deep: true,
      handler (newA, oldA) {
        if (oldA && newA && newA.ci === oldA.ci) {
          this.saveAudit(this.audit)
        }
      }
    }
  },
  mounted () {
    this.loadData()
  },
  computed: {
    ...mapGetters({
      criteria: 'manual/criteria',
      requirements: 'manual/reqmts',
      lastSync: 'global/lastSync',
      syncing: 'global/syncing',
      isOnline: 'global/isOnline',
      hasRole: 'auth/hasRole',
      hasOneOfRoles: 'auth/hasOneOfRoles',
      stateCurrent: 'audits/current',
      stateFilter: 'audits/criteriaFilter'
    }),
    allowEdit () {
      return this.audit.criteria.filter(this.filterAllowedEdit).sort(this.sortByTopicReqCri)
    },
    todos () {
      return this.allowEdit.filter((c) => c.grade === 0).sort(this.sortByTopicReqCri)
    },
    reviews () {
      return this.audit.criteria.filter((c) => c.review).sort(this.sortByTopicReqCri)
    },
    nos () {
      return this.audit.criteria.filter((c) => c.grade === 2 || c.grade === 1).sort(this.sortByTopicReqCri)
    },
    items () {
      switch (this.filter) {
        case 'todos':
          return this.todos
        case 'reviews':
          return this.reviews
        case 'nos':
          return this.nos
        default:
          return this.allowEdit
      }
    },
    unprocCount () {
      return this.allowEdit.reduce((counter, { grade }) => (grade ? counter : counter + 1), 0)
    },
    canSetAll () {
      return this.hasRole('QmsAdmin') && ['dev', 'test'].includes(process.env.VUE_APP_ENV)
    },
    current: {
      get () {
        return this.stateCurrent
      },
      set (c) {
        this.setStateCurrent(c)
      }
    },
    filter: {
      get () {
        return this.stateFilter
      },
      set (f) {
        this.setStateFilter(f)
      }
    }
  },
  methods: {
    ...mapMutations({
      setStateCurrent: 'audits/setCurrent',
      setStateFilter: 'audits/setCriteriaFilter'
    }),
    sortByTopicReqCri (a, b) {
      const reqA = this.requirements.find(c => c.ri === a.ri)
      const reqB = this.requirements.find(c => c.ri === b.ri)
      return a.tnr < b.tnr ? -1 : a.tnr > b.tnr ? 1 : reqA.rnr < reqB.rnr ? -1 : reqA.rnr > reqB.rnr ? 1 : a.ci < b.ci ? -1 : 1
    },
    filterAllowedEdit (c) {
      const cr = this.criteria.find(b => b.ci === c.ci)
      return cr ? cr.hintMask.includes('e') : false
    },
    async loadData () {
      this.loading = true
      const response = await AuditService.getAudit(this.$route.params.id, true)
      this.audit = response
      this.loading = false
      this.move()
    },
    async changeState (newState) {
      await AuditService.changeState(newState, this.audit)
      await AuditService.removeLocalAudit(this.audit)
      this.$router.push({ name: 'AuditSummary', params: { id: this.audit.id } })
    },
    confirmFinalize () {
      const bookmarks = this.reviews.length | 0
      const title = `${this.$options.filters.audtCatDescr(this.audit.cat)} beenden?`
      const msg = bookmarks
        ? `Es ${bookmarks === 1 ? 'ist' : 'sind'} noch ${bookmarks} ${
            bookmarks === 1 ? 'Kriterium' : 'Kriterien'
          } markiert. Trotzdem beenden?`
        : ''

      this.$buefy.dialog.confirm({
        title: title,
        message: msg,
        confirmText: 'Beenden',
        type: 'is-danger',
        cancelText: 'Nein',
        hasIcon: !!bookmarks,
        onConfirm: () => this.changeState('Closed')
      })
    },
    pageChange () {
      this.move()
    },
    filterChanged () {
      this.current = 1
      this.move()
    },
    move () {
      if (this.filter === 'todos' && this.curItem && this.curItem.grade !== 0) {
        this.current = 1
      }
      const item = this.items[this.current - 1]
      this.curItem = item ?? null
    },
    async saveAudit (newA) {
      try {
        await AuditService.saveAudit(newA)
      } catch (error) {
        this.$buefy.toast.open({
          message: 'Ein Fehler ist aufgetreten. Bitte öffnen Sie das Audit erneut.',
          type: 'is-danger'
        })
        this.$router.push({ name: 'Audits' })
      }
    },
    setAll () {
      this.audit.criteria.forEach((c) => {
        c.grade = 3
      })
    }
  }
}
</script>
